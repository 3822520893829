import React, { useState } from "react";
import * as ethers from "ethers";
import abi from "../assets/BidMaster.json";
import Loader from "./Loader";
import { getEthereumObject } from "../utils/functions";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const ApplyTender = ({ tenderId }) => {
	const [showApplyTender, setShowApplyTender] = useState(false);
	const [file, setFile] = useState("");
	const [loading, setLoading] = useState(false);

	const applyTenderHandler = (e) => {
		e.preventDefault();
		applyTender();
	};

	const resetForm = () => {
		setFile("");
		setShowApplyTender(false);
	};

	const applyTender = async () => {
		setLoading(true);
		const company = JSON.parse(localStorage.getItem("company"));
		if (!company) return alert("Invalid company details...");
		try {
			const { ethereum, error } = await getEthereumObject();
			if (error) return alert(error);
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				let txn = await contract.applyTender(tenderId, parseInt(company[0].hex, 16), file, {
					gasLimit: 20000000,
				});
				await txn.wait();
				txn = await contract.companyDetails(parseInt(company[0].hex, 16));
				localStorage.setItem("company", JSON.stringify(txn));
				alert("Tender Applied Successfully");
			}
		} catch (err) {
			console.log("Error: ", err);
		} finally {
			resetForm();
			setLoading(false);
		}
	};

	return loading ? (
		<Loader />
	) : (
		<>
			<button
				onClick={() => setShowApplyTender(true)}
				className="px-2 py-1 bg-blue-600 text-white font-medium rounded-md"
			>
				Click here...
			</button>
			{showApplyTender && (
				<div className="h-screen w-screen fixed top-0 left-0 bg-black/50 grid place-items-center">
					<div
						className="absolute top-5 right-5 bg-gray-300 text-2xl rounded-full w-10 h-10 grid place-items-center cursor-pointer"
						onClick={() => setShowApplyTender(false)}
					>
						X
					</div>
					<form
						onSubmit={applyTenderHandler}
						className="max-w-[80%] bg-white shadow-lg py-4 px-6 rounded-lg"
					>
						<input
							required
							value={file}
							onChange={(e) => setFile(e.target.value)}
							className="border border-gray-500 rounded-md py-2 px-4 w-full"
							type="url"
							placeholder="Proposal File (URL)"
						/>
						<button type="submit" className="mt-4 w-full text-center bg-blue-300 py-2 rounded-md">
							Submit
						</button>
					</form>
				</div>
			)}
		</>
	);
};

export default ApplyTender;
