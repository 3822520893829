import React, { useEffect, useState } from "react";
import * as ethers from "ethers";
import abi from "../assets/BidMaster.json";
import { getEthereumObject } from "../utils/functions";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import ApplicationsTable from "../components/TenderDetails/ApplicationsTable";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const TenderDetails = () => {
	const { tenderId } = useParams();
	const [tender, setTender] = useState(null);
	const [loading, setLoading] = useState(false);

	const fetchTenders = async () => {
		setLoading(true);
		try {
			const { ethereum } = await getEthereumObject();
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				const tenders = await contract.listTenders();
				setTender(Object.values(tenders)[tenderId]);
			}
		} catch (err) {
			console.log("Error: ", err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchTenders();
	}, []);

	return loading ? (
		<Loader />
	) : (
		<div className="text-white p-10 overflow-y-scroll">
			<h4 className="mb-2 text-4xl font-medium">{tender?.sectorName}</h4>
			<p className="font-medium">
				{tender?.description}
			</p>
			<div className="mt-4 flex items-center justify-evenly">
				<div>
					<p className="text-gray-400 font-medium">{tender?._address}</p>
					<div className="flex space-x-4">
						<p className="text-gray-400 font-medium">{tender?.city}</p>
						<p className="text-gray-400 font-medium">{tender?.state}</p>
					</div>
				</div>
				<div>
					<div className="flex space-x-2">
						<h4 className="font-medium">Start: </h4>
						<p>{new Date(parseInt(tender?.startDate)).toLocaleDateString()}</p>
					</div>
					<div className="flex space-x-2">
						<h4 className="font-medium">End: </h4>
						<p>{new Date(parseInt(tender?.endDate)).toLocaleDateString()}</p>
					</div>
				</div>
			</div>
      <h1 className="mt-4 mb-2 text-3xl text-center font-bold">Applications</h1>
      <ApplicationsTable applications={tender?.applications} />
		</div>
	);
};

export default TenderDetails;
