import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ApplicationsTable = ({ applications }) => {
	return Object.values(applications || {}).length <= 0 ? (
		<h1 className="mt-5 text-3xl text-red-400 font-bold text-center">No Applications Right Now...</h1>
	) : (
		<TableContainer className="" component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell align="center">Company Details</TableCell>
						<TableCell align="center">Proposal Document</TableCell>
						<TableCell align="center">Status</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Object.values(applications || {}).map((app) => (
						<TableRow key={app[0]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell align="center">
								<Link
									to={`/company/${app[2]}`}
									className="px-2 py-1 bg-blue-600 text-white font-medium rounded-md"
								>
									Click here...
								</Link>
							</TableCell>
							<TableCell align="center">
								<a
									href={app[3]}
									target="_blank"
									rel="noopener noreferer"
									className="text-blue-700 underline font-medium"
								>
									Click here...
								</a>
							</TableCell>
							<TableCell align="center">{app[4]}</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default ApplicationsTable;
