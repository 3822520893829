import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Header = ({ isAdmin, logout }) => {
	const [company, setCompany] = useState(null);
	useEffect(() => {
		const company = localStorage.getItem("company");
		if (!company) setCompany(null);
		else setCompany(JSON.parse(company));
	}, []);
	return (
		<div className="flex items-center justify-between py-4 px-8 text-xl text-white shadow-lg shadow-white/20">
			<NavLink to="/">BidMaster</NavLink>
			<p onClick={logout} className="cursor-pointer">
				Hi, {isAdmin ? "Admin" : company ? company[2] : "Company"}
			</p>
		</div>
	);
};

export default Header;
