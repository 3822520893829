import React, { useState } from "react";
import { getEthereumObject } from "../utils/functions";
import abi from "../assets/BidMaster.json";
import * as ethers from "ethers";
import Loader from "./Loader";


const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const CompanyAuth = ({ setIsLoggedIn, showModal, setShowModal }) => {
	const [isRegistering, setIsRegistering] = useState(false),
		[loading, setLoading] = useState(false);

	const [name, setName] = useState(""),
		[email, setEmail] = useState(""),
		[password, setPassword] = useState(""),
		[phone, setPhone] = useState(""),
		[address, setAddress] = useState("");

	const registerHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const { ethereum, error } = await getEthereumObject();
			if (error) return alert("Error: ", error);
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				let txn = await contract.createCompany(name, phone, address, email, password, {
					gasLimit: 20000000,
				});
				await txn.wait();
				alert("Company Registered Successfully...");
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};
	const loginHandler = async (e) => {
		e.preventDefault();
		setLoading(true);
		try {
			const { ethereum, error } = await getEthereumObject();
			if (error) return alert("Error: ", error);
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				let txn = await contract.loginCompany(email, password, {gasLimit: 20000000});
				// await txn.wait();
				localStorage.setItem("company", JSON.stringify(txn));
				setIsLoggedIn(true);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const registerForm = () => (
		<form onSubmit={registerHandler} className="space-y-4">
			<input
				required
				value={name}
				onChange={(e) => setName(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="text"
				placeholder="Company Name"
			/>
			<input
				required
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="email"
				placeholder="Email"
			/>
			<input
				required
				value={phone}
				onChange={(e) => setPhone(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="number"
				placeholder="Phone Number"
			/>
			<input
				required
				value={address}
				onChange={(e) => setAddress(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="text"
				placeholder="Address"
			/>
			<input
				required
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="password"
				placeholder="Password"
			/>
			<button type="submit" className="w-full text-center bg-blue-300 py-2 rounded-md">
				Register
			</button>
		</form>
	);
	const loginForm = () => (
		<form onSubmit={loginHandler} className="space-y-4">
			<input
				required
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="email"
				placeholder="Email"
			/>
			<input
				required
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				className="border rounded-md py-2 px-4 w-full"
				type="password"
				placeholder="Password"
			/>
			<button type="submit" className="w-full text-center bg-blue-300 py-2 rounded-md">
				Login
			</button>
		</form>
	);

	return loading ? <Loader /> : showModal && (
		<div className="h-screen w-screen fixed top-0 left-0 bg-black/50 grid place-items-center">
			<div
				className="absolute top-5 right-5 bg-gray-300 text-2xl rounded-full w-10 h-10 grid place-items-center cursor-pointer"
				onClick={() => setShowModal(false)}
			>
				X
			</div>
			<div className="max-w-[80%] bg-white shadow-lg py-4 px-6 rounded-lg">
				<div className="mb-4 w-full grid grid-cols-2 gap-4 place-items-center">
					<button
						onClick={() => setIsRegistering(false)}
						className={`w-full text-center py-2 rounded-md ${
							!isRegistering ? "bg-blue-300" : "bg-gray-300"
						}`}
					>
						Login
					</button>
					<button
						onClick={() => setIsRegistering(true)}
						className={`w-full text-center py-2 rounded-md ${
							isRegistering ? "bg-blue-300" : "bg-gray-300"
						}`}
					>
						Register
					</button>
				</div>
				{isRegistering ? registerForm() : loginForm()}
			</div>
		</div>
	);
};

export default CompanyAuth;
