import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import * as ethers from "ethers";
import abi from "../assets/BidMaster.json";
import { getEthereumObject } from "../utils/functions";
import { Link } from "react-router-dom";
import Loader from "./Loader";
import ApplyTender from "./ApplyTender";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const TableComponent = ({ isAdmin }) => {
	const [loading, setLoading] = useState(false),
		[tenders, setTenders] = useState([]);

	const fetchTenders = async () => {
		setLoading(true);
		try {
			const { ethereum } = await getEthereumObject();
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				const tenders = await contract.listTenders();
				setTenders(tenders);
			}
		} catch (err) {
			console.log("Error: ", err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		// registerAdmin();
		fetchTenders();
	}, []);

	return loading ? (
		<Loader />
	) : (
		<TableContainer className="" component={Paper}>
			<Table aria-label="simple table">
				<TableHead>
					<TableRow>
						<TableCell>SectorName</TableCell>
						<TableCell align="center">Start Date</TableCell>
						<TableCell align="center">End Date</TableCell>
						<TableCell align="center">File</TableCell>
						<TableCell align="center">Description</TableCell>
						<TableCell align="center">{isAdmin ? "Details" : "Apply"}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{tenders.map((tender) => (
						<TableRow key={tender[0]} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
							<TableCell>{tender.sectorName}</TableCell>
							<TableCell align="center">
								{new Date(parseInt(tender.startDate)).toLocaleDateString()}
							</TableCell>
							<TableCell align="center">
								{new Date(parseInt(tender.endDate)).toLocaleDateString()}
							</TableCell>
							<TableCell align="center">
								<a className="text-blue-600 underline" href={tender.file} target="_blank">
									{tender.file}
								</a>
							</TableCell>
							<TableCell align="center" className="line-clamp-1">
								{tender.description}
							</TableCell>
							<TableCell align="center">
								{isAdmin ? (
									<Link to={`/tender/${tender.id}`}
										className="px-2 py-1 bg-blue-600 text-white font-medium rounded-md"
									>
										Click here...
									</Link>
								) : (
									<ApplyTender tenderId={tender.id} />
								)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default TableComponent;
