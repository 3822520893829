import detectEthereumProvider from "@metamask/detect-provider";
import { networks } from "./networks";

export const getEthereumObject = async () => {
  const ethereum = await detectEthereumProvider();
  if (!ethereum || !ethereum.isMetaMask) {
    return { error: "Metamask Not Installed.", errorCode: 1, ethereum };
  }
  const chainId = await ethereum.request({ method: "eth_chainId" });
  // setNetwork(networks[chainId]);
  
  // if (chainId !== "0x80001" && chainId !== "0x13881") {
  // const switched = await switchNetwork(ethereum);
  // if (!switched) return { error: "Connected to different network", errorCode: 2, ethereum };
  // }
  return { ethereum };
};