import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import abi from "./assets/BidMaster.json";
import { getEthereumObject } from "./utils/functions";
import { networks } from "./utils/networks";
import Loader from "./components/Loader";
import CompanyAuth from "./components/CompanyAuth";
import * as ethers from "ethers";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./pages/Home";
import TenderDetails from "./pages/TenderDetails";
import CompanyPage from "./pages/CompanyPage";
// import router from "./utils/router";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const App = () => {
	const [currentAccount, setCurrentAccount] = useState(""),
		[network, setNetwork] = useState(""),
		[loading, setLoading] = useState(true),
		[showCompanyAuth, setShowCompanyAuth] = useState(false),
		[isAdmin, setIsAdmin] = useState(false),
		[isLoggedIn, setIsLoggedIn] = useState(false);

	const switchNetwork = async (ethereum) => {
		const networkData = [
			{
				chainId: "0x5",
				chainName: "Goerli Test Network",
				rpcUrls: ["https://goerli.infura.io/v3/"],
				nativeCurrency: {
					name: "Goerli ETH",
					symbol: "gorETH",
					decimals: 18,
				},
				blockExplorerUrls: ["https://goerli.etherscan.io"],
			},
		];
		try {
			await ethereum.request({
				method: "wallet_addEthereumChain",
				params: networkData,
			});
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};
	const checkIfConnected = async () => {
		const ethereum = await getEthereumObject();
		setLoading(true);
		if (ethereum.error) {
			if (ethereum.errorCode === 1) {
				setLoading(false);
				return alert(ethereum.error);
			}
		}
		const chainId = await ethereum.ethereum.request({ method: "eth_chainId" });
		const _network = networks[chainId];
		setNetwork(_network);
		const accounts = await ethereum.ethereum.request({ method: "eth_accounts" });
		if (accounts.length === 0) {
			setLoading(false);
			return console.log("No authorized account found.");
		}
		setCurrentAccount(accounts[0]);
		setLoading(false);
		ethereum.ethereum.on("chainChanged", handleChainChanged);
		// Reload the page when they change networks
		function handleChainChanged(_chainId) {
			window.location.reload();
		}
	};
	const connectWallet = async () => {
		try {
			const { ethereum, error, errorCode } = await getEthereumObject();
			if (errorCode === 1) {
				return alert(error);
			}
			// if (errorCode === 2) {
			// 	const switched = await switchNetwork(ethereum);
			// 	if (!switched) return console.log("Network switch request rejected.");
			// }
			setLoading(true);
			const accounts = await ethereum.request({ method: "eth_requestAccounts" });
			setCurrentAccount(accounts[0]);
			setLoading(false);
		} catch (error) {
			console.log(error);
		} finally {
			setLoading(false);
		}
	};

	// const registerAdmin = async () => {
	// 	setLoading(true);
	// 	try {
	// 		const { ethereum, error } = await getEthereumObject();
	// 		if (error) return alert(error);
	// 		if (ethereum) {
	// 			const provider = new ethers.providers.Web3Provider(ethereum);
	// 			const signer = provider.getSigner();
	// 			const contract = new ethers.Contract(contractAddress, contractABI, signer);
	// 			const txn = await contract.registerAdmin("admin@user.com", "password", { gasLimit: 200000 });
	// 			await txn.wait();
	// 			console.log("Admin registered successfully.");
	// 		}
	// 	} catch (err) {
	// 		console.log("Error: ", err);
	// 	}
	// };

	useEffect(() => {
		checkIfConnected();
		// registerAdmin();
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		const company = localStorage.getItem("company");
		const isAdmin = localStorage.getItem("admin");
		if (isAdmin) {
			setIsLoggedIn(true);
			setIsAdmin(true);
		}
		if (company) {
			setIsLoggedIn(true);
			setIsAdmin(false);
		}
	}, []);

	const RouterLayout = () => (
		<>
			<Header logout={logout} isAdmin={isAdmin} />
			<Outlet />
		</>
	);

	const router = createBrowserRouter([
		{
			element: <RouterLayout />,
			children: [
				{
					path: "/",
					element: <Home isAdmin={isAdmin} />,
				},
				{
					path: "/tender/:tenderId",
					element: <TenderDetails loading={loading} setLoading={setLoading} />,
				},
				{
					path: "/company/:companyId",
					element: <CompanyPage loading={loading} setLoading={setLoading} />,
				},
			],
		},
	]);

	const logout = () => {
		setIsLoggedIn(false);
		setIsAdmin(false);
		localStorage.removeItem("company");
		localStorage.removeItem("admin");
	};

	const adminLoginHandler = async () => {
		connectWallet();
		if (!currentAccount) return alert("Wallet must be connected before logging in.");
		setLoading(true);
		try {
			const { ethereum, error } = await getEthereumObject();
			if (error) return alert("Error: ", error);
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				let txn = await contract.walletIsAdmin();
				if (!txn) {
					setIsAdmin(false);
					return alert("You are not an Admin...");
				}
				localStorage.setItem("admin", true);
				setIsLoggedIn(true);
				setIsAdmin(true);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	const companyLoginHandler = () => {
		setIsAdmin(false);
		connectWallet();
		if (currentAccount) setShowCompanyAuth(true);
		else return alert("Wallet must be connected before logging in.");
	};

	const renderNotConnectedContainer = () => {
		if (!currentAccount) {
			return (
				<div className="w-screen h-screen grid place-items-center">
					<div className="connect-wallet-container">
						<img
							src="/logo.png"
							alt="Ninja gif"
							className="mb-6"
						/>
						<button onClick={adminLoginHandler} className="cta-button connect-wallet-button">
							Continue As Admin
						</button>
						<button
							onClick={companyLoginHandler}
							className="cta-button connect-wallet-button mt-5"
						>
							Continue As Company
						</button>
					</div>
				</div>
			);
		} else
			return (
				<div className="w-screen h-screen grid place-items-center">
					<div className="connect-wallet-container">
						<img
							src="/logo.png"
							alt="Ninja gif"
							className="mb-6"
						/>
						<button onClick={adminLoginHandler} className="cta-button connect-wallet-button">
							Continue As Admin
						</button>
						<CompanyAuth
							setIsLoggedIn={setIsLoggedIn}
							showModal={showCompanyAuth}
							setShowModal={setShowCompanyAuth}
						/>
						<button
							onClick={companyLoginHandler}
							className="cta-button connect-wallet-button mt-5"
						>
							Continue As Company
						</button>
					</div>
				</div>
			);
	};

	const renderWrongNetworkContainer = () => {
		if (network != "Goerli")
			return (
				<div className="w-screen h-screen grid place-items-center">
					<div className="connect-wallet-container">
						<img
							src="/logo.png"
							alt="Ninja gif"
							className="mb-6"
						/>
						<button className="cta-button connect-wallet-button">
							Switch to Goerli Test Network in Metamast
						</button>
					</div>
				</div>
			);
	};

	return loading ? (
		<Loader />
	) : !currentAccount ? (
		renderNotConnectedContainer()
	) : network != "Goerli" ? (
		renderWrongNetworkContainer()
	) : isAdmin || isLoggedIn ? (
		<div className="h-screen w-screen bg-[#0d1116] flex flex-col">
			<RouterProvider router={router} />
		</div>
	) : (
		renderNotConnectedContainer()
	);
};

export default App;
