import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getEthereumObject } from "../utils/functions";
import * as ethers from "ethers";
import abi from "../assets/BidMaster.json";
import ApplicationsTable from "../components/CompanyDetails/ApplicationsTable";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const CompanyPage = () => {
	const { companyId } = useParams();
	const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState(null);

	const fetchCompanyDetails = async () => {
		setLoading(true);
		try {
			const { ethereum } = await getEthereumObject();
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				const company = await contract.companyDetails(companyId);
				setCompany(company)
			}
		} catch (err) {
			console.log("Error: ", err);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
    fetchCompanyDetails();
  }, []);
	return <div className="text-white p-10">
    <h4 className="mb-2 text-4xl font-medium">{company?.name}</h4>
    <p className="text-gray-400">{company?.email}</p>
    <p className="text-gray-400">{company?.phone}</p>
    <p className="">{company?._address}</p>
    <h1 className="mt-4 mb-2 text-3xl text-center font-bold">Applications</h1>
    <ApplicationsTable applications={company?.applications} />
  </div>;
};

export default CompanyPage;
