import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddTender from "./AddTender";

const Navbar = ({ isAdmin }) => {
	const [showAddTender, setShowAddTender] = useState(false);
	return (
		<div className="h-full flex-[0.25] bg-white">
			{showAddTender && <AddTender showAddTender={showAddTender} setShowAddTender={setShowAddTender} />}
			{isAdmin ? (
				<p
					className="text-xl px-2 py-5 border-b-2 text-center hover:bg-[#0d1116]/25 cursor-pointer transition-all duration-150"
					onClick={() => setShowAddTender(true)}
				>
					Add Tenders
				</p>
			) : (
				<p className="text-xl px-2 py-5 border-b-2 text-center hover:bg-[#0d1116]/25 cursor-pointer transition-all duration-150">
				<Link
					to={`/company/${parseInt(JSON.parse(localStorage.getItem("company"))[0].hex, 16)}`}
				>
					Show Applied Tenders
				</Link>
				</p>
			)}
			{/* <p className='text-xl px-2 py-5 border-b-2 text-center hover:bg-[#0d1116]/25 cursor-pointer transition-all duration-150'>Ongoing Tenders</p> */}
			{/* <p className='text-xl px-2 py-5 border-b-2 text-center hover:bg-[#0d1116]/25 cursor-pointer transition-all duration-150'>Past Tenders</p> */}
		</div>
	);
};

export default Navbar;
