import React from 'react'

const Loader = () => {
  return (
    <div className="h-screen w-screen fixed top-0 left-0 bg-black/70 grid place-items-center">
      <h1 className='text-7xl font-bold text-white'>Loading ...</h1>
    </div>
  )
}

export default Loader