import React, { useState } from "react";
import moment from "moment";
import * as ethers from "ethers";
import { getEthereumObject } from "../utils/functions";
import abi from "../assets/BidMaster.json";
import Loader from "./Loader";

const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
const contractABI = abi.abi;

const AddTender = ({ showAddTender, setShowAddTender }) => {
	const [sector, setSector] = useState(""),
		[address, setAddress] = useState(""),
		[city, setCity] = useState(""),
		[state, setState] = useState(""),
		[pinCode, setPinCode] = useState(""),
		[file, setFile] = useState(""),
		[startDate, setStartDate] = useState(moment().startOf("day").format("yyyy-MM-DD")),
		[endDate, setEndDate] = useState(moment().startOf("day").add(1, "days").format("yyyy-MM-DD")),
		[description, setDescription] = useState("");

	const [loading, setLoading] = useState(false);

	const addTenderHandler = (e) => {
		e.preventDefault();
		createTender();
	};

	const resetForm = () => {
		setSector("");
		setAddress("");
		setCity("");
		setPinCode("");
		setFile("");
		setStartDate(moment().startOf("day").format("yyyy-MM-DD"));
		setEndDate(moment().startOf("day").add(1, "days").format("yyyy-MM-DD"));
		setDescription("");
	};

	const createTender = async () => {
		setLoading(true);
		try {
			const { ethereum, error } = await getEthereumObject();
			if (error) return alert(error);
			if (ethereum) {
				const provider = new ethers.providers.Web3Provider(ethereum);
				const signer = provider.getSigner();
				const contract = new ethers.Contract(contractAddress, contractABI, signer);
				let txn = await contract.createTender(
					sector,
					Date.parse(endDate) - Date.parse(startDate),
					address,
					city,
					state,
					Number(pinCode),
					file,
					Date.parse(startDate),
					Date.parse(endDate),
					description,
					{ gasLimit: 20000000 }
				);
				await txn.wait();
				alert("Tender created Successfully");
			}
		} catch (err) {
			console.log("Error: ", err);
		} finally {
			resetForm();
			setLoading(false);
		}
	};

	return loading ? (
		<Loader />
	) : (
		<div className="h-screen w-screen fixed top-0 left-0 bg-black/50 grid place-items-center">
			<div
				className="absolute top-5 right-5 bg-gray-300 text-2xl rounded-full w-10 h-10 grid place-items-center cursor-pointer"
				onClick={() => setShowAddTender(false)}
			>
				X
			</div>
			<form onSubmit={addTenderHandler} className="max-w-[80%] bg-white shadow-lg py-4 px-6 rounded-lg">
				<div className="grid grid-cols-3 place-items-center gap-4">
					<input
						required
						value={sector}
						onChange={(e) => setSector(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="text"
						placeholder="Sector Name"
					/>
					<input
						required
						value={address}
						onChange={(e) => setAddress(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="text"
						placeholder="Address"
					/>
					<input
						required
						value={city}
						onChange={(e) => setCity(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="text"
						placeholder="City"
					/>
					<input
						required
						value={state}
						onChange={(e) => setState(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="text"
						placeholder="State"
					/>
					<input
						required
						value={pinCode}
						onChange={(e) => setPinCode(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="number"
						placeholder="Pin Code"
					/>
					<input
						required
						value={file}
						onChange={(e) => setFile(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="url"
						placeholder="Document (URL)"
					/>
					<input
						required
						value={startDate}
						onChange={(e) => setStartDate(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="date"
						placeholder="Start Date"
						min={moment().startOf("day").format("yyyy-MM-DD")}
					/>
					<input
						required
						value={endDate}
						onChange={(e) => setEndDate(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="date"
						placeholder="End Date"
						min={moment(startDate).add(1, "day").format("yyyy-MM-DD")}
					/>
					<input
						required
						value={description}
						onChange={(e) => setDescription(e.target.value)}
						className="border rounded-md py-2 px-4 w-full"
						type="text"
						placeholder="Description"
					/>
				</div>
				<button type="submit" className="mt-4 w-full text-center bg-blue-300 py-2 rounded-md">
					Proceed to Add
				</button>
			</form>
		</div>
	);
};

export default AddTender;
