import React from "react";
import Table from "../components/Table";
import Navbar from "../components/Navbar";

const Home = ({ isAdmin }) => {
	return (
		<div className="flex flex-1">
			<Navbar isAdmin={isAdmin} />
			<div className="h-full flex-1 grid place-items-center px-10 max-w-6xl mx-auto">
				<Table isAdmin={isAdmin} />
			</div>
		</div>
	);
};

export default Home;
